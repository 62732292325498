<template>
  <div class="succeed">
    <div class="box">
      <div class="order_info">
        <h1>挂号成功</h1>
        <p>
          就诊人：
          <span>{{ query.patName }}</span>
        </p>
        <p>
          医生姓名：
          <span>{{ query.doctorName }}</span>
        </p>
        <p>
          挂号科室：
          <span>{{ query.deptName }}</span>
        </p>
        <p>
          导诊信息：
          <span>{{ query.position }}</span>
        </p>
        <p>
          就诊日期：
          <span>{{ query.scheduleDate }}</span>
        </p>
        <p>
          就诊时段：
          <span>{{ query.beginTime }}~{{ query.endTime }}</span>
        </p>
      </div>
    </div>
    <h1 class="pleaseTip">请记得带走您的小票！</h1>
    <p class="count">返回首页：{{ count }}</p>
    <el-button type="primary" @click.native="home" class="home">首页</el-button> <br>

    <el-row class="footer">
    </el-row>
  </div>
</template>
<script>
import qs from "qs";
import { jsonPost, formPost } from "@/baseAPI";
export default {
  data () {
    return {
      query: {},
      count: '',
      timer: '',
      isToday: '0'
    }
  },
  created () {
    this.query = this.$route.query
    this.print()
    this.countdown()
    if (this.$route.query.isToday == '1') {
      this.hisQueueup()
      setTimeout(() => {
      }, 2000);
    }

  },
  beforeDestroy () {
    clearInterval(this.timer);
    this.timer = null;
  },
  beforeDestroy () {
    clearInterval(this.timer);
    this.timer = null;
  },
  mounted () {
    // setTimeout(() => {
    //   this.$router.push('/home')
    // }, 100000);
  },
  methods: {
    async hisQueueup () {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const { data: res } = await jsonPost(
        "/appointmentRecord/record/hisQueueup",
        {
          departmentId: this.query.deptId,
          doctorId: this.query.doctorId,
          serialNumber: this.query.orderNum,
        }
      );
      if (res.code === 0) {
        let printNo = res.data.PrintNo
        let doctorCode = res.data.DoctorID
        let WaitCount = res.data.WaitCount

        // this.reportedReceipt(printNo, doctorCode, WaitCount)
        loading.close()
      } else {
        loading.close()
      }
    },
    // 打印排队凭条
    // reportedReceipt (PrintNo, DoctorID, WaitCount) {
    //   let _this = this
    //   const params = {
    //     SerialNumber: this.query.orderNum,
    //     PrintNo: PrintNo,
    //     DoctorID: DoctorID,
    //     WaitCount: WaitCount
    //   }
    //   console.log(111, params);
    //   Promise.all([_this.$api.reportedReceipt(params)]).then((data) => {

    //   });
    // },
    home () {
      this.$router.push('/home')
    },
    print () {
      let _this = this
      const params = {
        orderNum: this.query.orderNum,
        name: this.query.patName,
        patCardNo: this.query.patCardNo,
        cost: this.query.regFee,
        outTradeNo: this.query.outTradeNo,
        remark: this.query.position,
        deptName: this.query.deptName,
        doctorName: this.query.doctorName,
        date: this.query.scheduleDate + '  ' + this.query.beginTime + '-' + this.query.endTime,
        invoiceUrl: ''
        // orderNum: '202301030004',
        // name: '吴事实上',
        // patCardNo: '20409413',
        // cost: '6',
        // outTradeNo: '2301031709012879',
        // remark: '1楼西区102室',
        // doctorName: '陈雯韩',
        // deptName: '神经内科门诊',
      }
      this.loading = true
      Promise.all([_this.$api.regPrint(params)]).then((data) => {

      });
    },

    countdown () {
      const TIME_COUNT = 30
      this.count = TIME_COUNT
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--
          if (this.count == 0) {
            this.$router.push('/home')
            console.log("succeed");
          }
        } else {
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
  }
}
</script>

<style scoped>
.succeed {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 30%;
}

.pleaseTip {
  color: red;
  text-align: center;
  font-size: 42px;
}

.box {
  text-align: center;
  padding: 60px 0;
}

.order_info p {
  margin-top: 70px;
  font-size: 50px;
  color: #81ace4;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 40px;
  right: 30px;
  margin-left: 47%;
  font-size: 30px !important;
}

.count {
  position: absolute;
  top: 60px;
  right: 40px;
  font-size: 34px;
  color: red;
}

.print {
  margin-left: 45%;
  font-size: 30px !important;
}

.footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}
</style>

